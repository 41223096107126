import { lazy } from "react";

const Dashboard1 = lazy(() => import("./dashboard1/Dashboard1"));
const Dashboard2 = lazy(() => import("./dashboard2/Dashboard2"));

const liveManager = lazy(() => import("./liveManager/liveManager"));

const newLive = lazy(() => import("./newLive"));
const storesList = lazy(() => import("./storesList"));
const livesList = lazy(() => import("./livesList"));
const productsList = lazy(() => import("./productsList"));
const productForm = lazy(() => import('./productForm'));
const productIntegrationsList = lazy(() => import('./productIntegrationsList'));
const profile = lazy(() => import('./profile'));
const connSellerfaces = lazy(() => import('./connectorSellerfacesConfig'))

const dashboardRoutes = [
  {
    path: "/lives/manage",
    component: liveManager
  },
  {
    path: "/lives/new",
    component: newLive
  },
  {
    path: "/stores",
    component: storesList
  },
  {
    path: "/lives",
    component: livesList
  },
  {
    exact: true,
    path: "/products",
    component: productsList
  },
  {
    path: "/products/new",
    component: productForm
  },
  {
    path: "/product-integrations",
    component: productIntegrationsList
  },
  {
    path: "/profile",
    component: profile
  },
  {
    path: '/conn-sf',
    component: connSellerfaces
  }
];

export default dashboardRoutes;
