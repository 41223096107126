import React, { useState, useEffect } from 'react';

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import firebaseConfig from "./firebaseConfig";
import { findOrCreateUser } from '../../services/UserService';

export const AuthContext = React.createContext();

function AuthProvider({ children }) {

  const [user, setUser] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
    const unsubscribe = firebase.auth().onIdTokenChanged(handleUser);
    return () => unsubscribe();
  }, []);

  const handleUser = async (user) => {
    if (user) {
      //create in db if firebaseUid not exists
      const u = convertUserModel(user);
      const resUser = await findOrCreateUser(u);
      console.log(resUser.data);
      setUser({...u, ...resUser.data});
      console.log(firebase.auth().currentUser)
      localStorage.setItem('m7sUser', JSON.stringify(firebase.auth().currentUser))
    } else {
      setUser();
      localStorage.removeItem('m7sUser')
    }
  }

  const signOut = () => {
    setUser();
    return firebase
      .auth()
      .signOut()
      .then(() => handleUser(false));
  };

  const init = () => {
    firebase.initializeApp(firebaseConfig);
  };

  const signUpWithEmailAndPassword = async (email, password) => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
    } catch (e) {
      console.log(e);
    }

  };

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  const signInWithGoogle = async () => {
    setLoading(true);
    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((response) => {
        setErrors(false);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setErrors(err);
        setLoading(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signUpWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signOut
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function convertUserModel(user) {
  return {
    firebaseId: user?.uid,
    email: user?.email,
    name: user?.displayName,
    provider: user?.providerData[0]?.providerId,
    emailVerified: user?.emailVerified,
    photoURL: user?.photoURL
  };
};

export default AuthProvider;