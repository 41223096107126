import api from './api';

export function getAllStores() {
    return api.get('/store');
}

export function deleteStore(store) {
    return api.delete('/store/' + store.id);
}

export function addNewStore(payload) {
    return api.post('/store', payload);
}

export function updateStore(payload) {
    return api.patch('/store/' + payload.id, payload);
}
