import "../fake-db";
import React, { Suspense } from "react";
import "../styles/app/app.scss";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import AuthProvider from "./context/AuthContext";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import StoreProvider from "./context/StoreContext";

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <AuthProvider>
          <StoreProvider>
            <Suspense fallback={<Loading></Loading>}>
              <Router history={history}>{renderRoutes(RootRoutes)}</Router>
            </Suspense>
          </StoreProvider>
        </AuthProvider>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
