import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { merge } from "lodash";
import {
  setLayoutSettings,
  setDefaultSettings
} from "app/redux/actions/LayoutActions";
import { classList } from "@utils";
import ScrollBar from "react-perfect-scrollbar";

class Customizer extends Component {
  state = {};

  render() {
    let { settings } = this.props;

    return (
      <div
        id="customizer"
        className={classList({
          customizer: true,
          open: settings.customizer.open
        })}
      >
        <ScrollBar
          className="customizer-body"
          data-perfect-scrollbar
          data-suppress-scroll-x="true"
        >
        </ScrollBar>
      </div>
    );
  }
}

Customizer.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  settings: state.layout.settings
});

export default connect(mapStateToProps, {
  setLayoutSettings,
  setDefaultSettings
})(Customizer);