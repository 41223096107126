const firebaseConfig = {
  apiKey: "AIzaSyCTQdC8m34FL9DWuH9HkIIxGZPW7o6nA6E",
  authDomain: "live-commerce-33ef9.firebaseapp.com",
  projectId: "live-commerce-33ef9",
  storageBucket: "live-commerce-33ef9.appspot.com",
  messagingSenderId: "165545108724",
  appId: "1:165545108724:web:0bac01cfa8099a88a1aaa1"
};

export default firebaseConfig;
