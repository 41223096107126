import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL || 'http://localhost:8080'}`
});

api.interceptors.request.use(
    (config) => {
        //if no token, leave blank backend will allow public only
        const u = localStorage?.getItem('m7sUser') || false
        //console.log(`m7sAxiosInterceptor interceptor... `, u)
        let token = false
        try {
            token = JSON.parse(u)?.stsTokenManager?.accessToken || false
        } catch (e) {
            console.log(`m7sAxiosInterceptor Invalid token. Ignoring for public usage`)
            console.log(e)
        }
        if (token)
            config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

//TODO Implement REFRESH TOKEN LOGIC
//   instance.interceptors.response.use(
//     (res) => {
//       return res;
//     },
//     async (err) => {
//       const originalConfig = err.config;
//       if (originalConfig.url !== "/auth/signin" && err.response) {
//         // Access Token was expired
//         if (err.response.status === 401 && !originalConfig._retry) {
//           originalConfig._retry = true;
//           try {
//             const rs = await instance.post("/auth/refreshtoken", {
//               refreshToken: TokenService.getLocalRefreshToken(),
//             });
//             const { accessToken } = rs.data;
//             TokenService.updateLocalAccessToken(accessToken);
//             return instance(originalConfig);
//           } catch (_error) {
//             return Promise.reject(_error);
//           }
//         }
//       }
//       return Promise.reject(err);
//     }
//   );

export default api;