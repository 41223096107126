import React, { useState, useEffect, useContext } from 'react';
import { getAllStores } from '../../services/StoreService';
import { AuthContext } from './AuthContext';

export const StoreContext = React.createContext();

const StoreProvider = (props) => {

    const { user } = useContext(AuthContext);
    const [stores, setStores] = useState([]);

    useEffect(() => {
        if(user)
            fetchStores();
    }, [user]);

    const fetchStores = async () => {
        try {
            const res = await getAllStores();
            setStores(res.data);
        } catch (error) {
            console.log(error); 
        }
    }

    return (
        <StoreContext.Provider 
            value={{
                stores,
                fetchStores
            }}
        >
            {props.children}
        </StoreContext.Provider>
    )
}

export default StoreProvider;